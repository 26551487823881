import api from "../../../../utils/api";

export const getPaidList = (page, limit, search = '', user_id = '', sortOrder = '') => {
  let url = `${process.env.REACT_APP_BASE_MARKETPLACE_URL_NEW}api/market-place-jobs/getJobList/cms?paid_job=true&limit=${limit}&page=${page}`;

  if (search) url += `&search=${encodeURIComponent(search)}`;
  if (user_id) url += `&createdBy=${user_id}`;
  if (sortOrder) url += `&sortBy=applicant_count&sortOrder=${sortOrder}`;

  return api.get(url);
};

export const getFreeList = (page, limit, search = '', priority = '', sortOrder = '') => {
  let url = `${process.env.REACT_APP_BASE_MARKETPLACE_URL_NEW}api/market-place-jobs/getJobList/cms?limit=${limit}&page=${page}`;

  if (search) url += `&search=${encodeURIComponent(search)}`;
  if (priority) url += `&priority=${priority.toUpperCase()}`;
  if (sortOrder) url += `&sortBy=applicant_count&sortOrder=${sortOrder}`;

  return api.get(url);
};