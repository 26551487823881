import api from "../../../../utils/api";

export const getPaidListapi = (page, limit, search = '') => {
  let url = `${process.env.REACT_APP_BASE_MARKETPLACE_URL_NEW}api/market-place-jobs/getJobList/cms?paid_job=true&before_broadcast=true&limit=${limit}&page=${page}`;
  if (search) url += `&search=${encodeURIComponent(search)}`;
  return api.get(url);
};

export const getPaidVendorListapi = (page, limit, search = '') => {
  let url = `${process.env.REACT_APP_BASE_MARKETPLACE_URL_NEW}api/market-place-jobs/getJobList/cms?paid_job=true&before_broadcast=true&jobVisibility=VENDORONLY&limit=${limit}&page=${page}`;
  if (search) url += `&search=${encodeURIComponent(search)}`;
  return api.get(url);
};

export const getPaidOrganicListapi = (page, limit, search = '') => {
  let url = `${process.env.REACT_APP_BASE_MARKETPLACE_URL_NEW}api/market-place-jobs/getJobList/cms?paid_job=true&before_broadcast=true&jobVisibility=ORGANIC&limit=${limit}&page=${page}`;
  if (search) url += `&search=${encodeURIComponent(search)}`;
  return api.get(url);
};

export const getBroadcastListapi = (page, limit, search = '') => {
  let url = `${process.env.REACT_APP_BASE_MARKETPLACE_URL_NEW}api/market-place-jobs/getJobList/cms?before_broadcast=true&limit=${limit}&page=${page}`; 
  if (search) url += `&search=${encodeURIComponent(search)}`;
  return api.get(url);
};

export const getVendorOnlyListapi = (page, limit, search = '') => {
  let url = `${process.env.REACT_APP_BASE_MARKETPLACE_URL_NEW}api/market-place-jobs/getJobList/cms?before_broadcast=true&jobVisibility=VENDORONLY&limit=${limit}&page=${page}`;
  if (search) url += `&search=${encodeURIComponent(search)}`;
  return api.get(url);
};

export const getOrganicListapi = (page, limit, search = '') => {
  let url = `${process.env.REACT_APP_BASE_MARKETPLACE_URL_NEW}api/market-place-jobs/getJobList/cms?before_broadcast=true&jobVisibility=ORGANIC&limit=${limit}&page=${page}`;
  if (search) url += `&search=${encodeURIComponent(search)}`;
  return api.get(url);
};

export const postJobApi = (jobId, payload) => {
  return api.patch(`${process.env.REACT_APP_BASE_MARKETPLACE_URL_NEW}api/market-place-jobs/${jobId}`, payload);
};