import React, { useState, useRef, useEffect } from "react";
import SearchIcon from "@mui/icons-material/Search";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';


const SearchableClientsDropdown = ({ 
  userList, 
  selectedUser , 
  setSelectedUser  
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const dropdownRef = useRef(null);
  
  const filteredUsers = userList.filter(user => 
    user?.user?.company?.toLowerCase().includes(searchInput.toLowerCase())
  );

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSelect = (userId) => {
    setSelectedUser (userId);
    setIsOpen(false);
    setSearchInput("");
  };

  const getDisplayValue = () => {
    if (!selectedUser ) return "Select Clients";
    
    const selected = userList.find(user => user.user_id === selectedUser );
    return selected?.user?.company || "Select Clients";
  };

  return (
    <div ref={dropdownRef} className="relative">
      <div 
        className="px-3 py-1.5 mr-2 mb-2 form-control block w-[170px] text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out cursor-pointer flex justify-between items-center"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="truncate">{getDisplayValue()}</span>
        <span className="ml-4"><KeyboardArrowDownIcon /></span>  
      </div>
      
      {isOpen && (
        <div className="absolute z-10 mt-1 w-full bg-white border border-gray-300 rounded-md shadow-lg max-h-60 overflow-auto">
          <div className="sticky top-0 bg-white p-2 border-b border-gray-200">
            <div className="relative">
              <input
                type="text"
                className="w-full px-3 py-2 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#2EBAA3]"
                placeholder="Search clients..."
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
                onClick={(e) => e.stopPropagation()}
              />
              <SearchIcon className="absolute right-3 top-2 text-gray-400" />
            </div>
          </div>
          
          <div>
            {filteredUsers.length > 0 ? (
              filteredUsers.map((user, index) => (
                <div
                  key={index}
                  className="px-4 py-2 hover:bg-gray-100 cursor-pointer truncate"
                  onClick={() => handleSelect(user.user_id)}
                >
                  {user?.user?.company || "Unknown Company"}
                </div>
              ))
            ) : (
              <div className="px-4 py-2 text-gray-500">No clients found</div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default SearchableClientsDropdown;