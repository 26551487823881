import React, { useEffect, useState } from "react";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useNavigate } from "react-router-dom";
import { Select, MenuItem, Chip, InputLabel, FormControl, Button, TablePagination } from "@mui/material";
import { getPaidListapi, getFreeListapi, postJobApi, getOrganicListapi, getVendorOnlyListapi, getBroadcastListapi, getPaidVendorListapi, getPaidOrganicListapi } from "./Helper";
import { useQueryParams } from "../../../hooks";

const JobMJobList = () => {
  const [jobs, setJobs] = useState([]);
  const [selectedTab, setSelectedTab] = useState('paid');
  const [selectedFreeSubTab, setSelectedFreeSubTab] = useState('broadcasted');
  const [executiveSelection, setExecutiveSelection] = useState({});
  const [channelSelection, setChannelSelection] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [prioritySelection, setPrioritySelection] = useState({});
  const [selectedPaidSubTab, setSelectedPaidSubTab] = useState('broadcasted');

  const { getParam, getAllQueryParamString, setMultiParam, removeParam } = useQueryParams();
  const currentPage = getParam("page");
  const currentLimit = getParam("pageSize");
  const [page, setPage] = useState(parseInt(currentPage) || 0);
  const [pageSize, setPageSize] = useState(parseInt(currentLimit) || 10);
  const [totalCount, setTotalCount] = useState(0);

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleExecutiveChange = (id, value) => {
    setExecutiveSelection((prev) => ({ ...prev, [id]: value }));
  };
  const handlePriorityChange = (id, value) => {
    setPrioritySelection((prev) => ({ ...prev, [id]: value }));
  };

  const handleChannelChange = (id, value) => {
    setChannelSelection((prev) => ({ ...prev, [id]: value }));
  };

  const navigate = useNavigate();
  
  const fetchJobs = (search = searchTerm, currentPage = page + 1, rowsPerPage = pageSize) => {
    let apiCall;
    
    if (selectedTab === 'paid') {
      if (selectedPaidSubTab === 'broadcasted') {
        apiCall = getPaidListapi;
      } else if (selectedPaidSubTab === 'vendoronly') {
        apiCall = getPaidVendorListapi;
      } else if (selectedPaidSubTab === 'organic') {
        apiCall = getPaidOrganicListapi;
      }
    } else if (selectedTab === 'broadcast') {
      if (selectedFreeSubTab === 'broadcasted') {
        apiCall = getBroadcastListapi;
      } else if (selectedFreeSubTab === 'vendoronly') {
        apiCall = getVendorOnlyListapi;
      } else if (selectedFreeSubTab === 'organic') {
        apiCall = getOrganicListapi;
      }
    }
    
    apiCall(currentPage, rowsPerPage, search).then((data) => {
      setJobs(data.data.data);
      const total = data.data.total || 0;
      setTotalCount(total);
    }).catch(error => {
      console.error(`Error fetching ${selectedTab} jobs:`, error);
      setJobs([]);
      setTotalCount(0);
    });
  };
  
  useEffect(() => {
    fetchJobs(searchTerm, page + 1);
  }, [selectedTab, selectedFreeSubTab, selectedPaidSubTab, page, pageSize]);

  useEffect(() => {
    setMultiParam({"page": page, "pageSize": pageSize});
  }, [page, pageSize]);

  const handleTabChange = (tab) => {
    setSelectedTab(tab);
    setPage(0);
    
    if (tab === 'broadcast') {
      setSelectedFreeSubTab('broadcasted');
    } else if (tab === 'paid') {
      setSelectedPaidSubTab('broadcasted');
    }
  };
  
  const handleFreeSubTabChange = (subTab) => {
    setSelectedFreeSubTab(subTab);
    setPage(0);
  };

  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    fetchJobs(value, page + 1, pageSize);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    fetchJobs(searchTerm, newPage + 1, pageSize);
  };

  const handleChangeRowsPerPage = (event) => {
    const newPageSize = parseInt(event.target.value, 10);
    setPageSize(newPageSize);
    setPage(0);
    fetchJobs(searchTerm, 1, newPageSize);
  };

  const handleSave = (jobId) => {
    const executiveName = executiveSelection[jobId] || "";
    const broadcastChannels = channelSelection[jobId] || [];
    const priority = selectedTab === "broadcast" ? (prioritySelection[jobId] || "").toUpperCase() : undefined;
    if (broadcastChannels.length === 0) {
      console.warn(`No channels selected for job ID: ${jobId}`);
      return;
    }

    const payload = {
      broadcast_by: executiveName,
      broadcast_platforms: broadcastChannels,
      ...(priority && { priority }), 
    };

    postJobApi(jobId, payload)
      .then(response => {
        setSnackbarMessage('Job broadcasted successfully');
        setOpenSnackbar(true);
        fetchJobs(searchTerm, page + 1, pageSize);
      })
      .catch(error => {
        console.error(`Error posting job with ID: ${jobId}`, error);
      });
  };

  return (
    <div className="p-5 shadow-md bg-white">
      <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} open={openSnackbar} autoHideDuration={6000} onClose={() => setOpenSnackbar(false)}>
        <MuiAlert onClose={() => setOpenSnackbar(false)} severity="success" sx={{ width: '100%' }}>
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
      <div className="grid grid-cols-6 gap-2">
        <h3 className="col-span-5 text-lg uppercase tracking-wider font-bold text-[#2EBAA3]">
          Manage Broadcast Jobs
        </h3>
        <input
          type="text"
          id="search"
          placeholder="Search"
          value={searchTerm}
          onChange={handleSearch}
          className="px-3 py-1.5 mr-2 mb-2 form-control block w-full text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none"
        />
      </div>
      <div className="flex border-b border-gray-300 mb-4">
        <button
          className={`py-2 px-6 ${selectedTab === "paid" ? "border-b-2 border-[#2EBAA3] text-[#2EBAA3] font-bold" : "text-gray-500 font-bold"}`}
          onClick={() => handleTabChange("paid")}
        >
          PAID JOBS
        </button>
        <button
          className={`py-2 px-6 ml-4 ${selectedTab === "broadcast" ? "border-b-2 border-[#2EBAA3] text-[#2EBAA3] font-bold" : "text-gray-500 font-bold"}`}
          onClick={() => handleTabChange("broadcast")}
        >
          FREE JOBS
        </button>
      </div>

      {selectedTab === 'paid' && (
        <div className="flex border-b border-gray-300 mb-4 text-sm">
          <button
            className={`py-2 px-6 ${selectedPaidSubTab === "broadcasted" ? "border-b-2 border-[#2EBAA3] text-[#2EBAA3] font-semibold" : "text-gray-500"}`}
            onClick={() => setSelectedPaidSubTab("broadcasted")}
          >
            BROADCASTED JOBS
          </button>
          <button
            className={`py-2 px-6 ml-4 ${selectedPaidSubTab === "vendoronly" ? "border-b-2 border-[#2EBAA3] text-[#2EBAA3] font-semibold" : "text-gray-500"}`}
            onClick={() => setSelectedPaidSubTab("vendoronly")}
          >
            VENDORONLY JOBS
          </button>
          <button
            className={`py-2 px-6 ml-4 ${selectedPaidSubTab === "organic" ? "border-b-2 border-[#2EBAA3] text-[#2EBAA3] font-semibold" : "text-gray-500"}`}
            onClick={() => setSelectedPaidSubTab("organic")}
          >
            ORGANIC JOBS
          </button>
        </div>
      )}
      
      {selectedTab === 'broadcast' && (
        <div className="flex border-b border-gray-300 mb-4 text-sm">
          <button
            className={`py-2 px-6 ${selectedFreeSubTab === "broadcasted" ? "border-b-2 border-[#2EBAA3] text-[#2EBAA3] font-semibold" : "text-gray-500"}`}
            onClick={() => handleFreeSubTabChange("broadcasted")}
          >
            BROADCASTED JOBS
          </button>
          <button
            className={`py-2 px-6 ml-4 ${selectedFreeSubTab === "vendoronly" ? "border-b-2 border-[#2EBAA3] text-[#2EBAA3] font-semibold" : "text-gray-500"}`}
            onClick={() => handleFreeSubTabChange("vendoronly")}
          >
            VENDORONLY JOBS
          </button>
          <button
            className={`py-2 px-6 ml-4 ${selectedFreeSubTab === "organic" ? "border-b-2 border-[#2EBAA3] text-[#2EBAA3] font-semibold" : "text-gray-500"}`}
            onClick={() => handleFreeSubTabChange("organic")}
          >
            ORGANIC JOBS
          </button>
        </div>
      )}
      
      <div className="overflow-x-auto relative">
        <table className="w-full text-sm text-left text-gray-500">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50">
            <tr>
              <th scope="col" className="py-3 px-3 uppercase w-[50px]">
                ID
              </th>
              <th scope="col" className="py-3 px-3 uppercase w-[200px]">
                Job Name
              </th>
              <th scope="col" className="py-3 px-3 uppercase w-[200px]">
                Company Name
              </th>
              <th scope="col" className="py-3 px-3 uppercase w-[200px]">
                User Type
              </th>
              <th scope="col" className="py-3 px-3 uppercase w-[200px]">
                Job Type
              </th>
              <th scope="col" className="py-3 px-3 uppercase w-[200px]">
                Salary
              </th>
              <th scope="col" className="py-3 px-3 uppercase w-[200px]">
                Posted Date
              </th>
              <th scope="col" className="py-3 px-3 uppercase w-[200px]">
                Executive Name
              </th>
              <th scope="col" className="py-3 px-3 uppercase w-[200px]">
                {selectedTab === "paid" ? "Broadcasted Channels" : "Publishing Channels"}
              </th>
              {selectedTab !== "paid" && (
                <th scope="col" className="py-3 px-6 uppercase w-[200px]">
                  Priorities
                </th>
              )}
              <th scope="col" className="py-3 px-3 uppercase w-[150px]">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {jobs && jobs.length > 0 ? (
              jobs.map((job, index) => {
                const itemIndex = page * pageSize + index + 1;
                return (
                <tr className="bg-white border-b" key={job.id}>
                  <td className="py-4 px-2">{itemIndex}</td>
                  <td className="py-4 px-2">
                    <a
                      href={`${window.location.origin}/dashboard/marketplace?id=${job.id}`}
                      className="cursor-pointer"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {job.name}
                    </a>
                  </td>

                  <td className="py-4 px-2">{job.vendorName || job.company_name || "N/A"}</td>
                  <td className="py-4 px-2">{job.role}</td>
                  <td className="py-4 px-2">{job.job_type}</td>
                  <td className="py-4 px-2">
                    {job.salary && (job.salary.min || job.salary.max) ? (
                      <>
                        {job.salary.currency === "INR" ? "₹" : job.salary.currency === "USD" ? "$" : ""}
                        {job.salary.min && job.salary.max ? (
                          <>
                            {job.salary.min < 1000 ? job.salary.min : `${(job.salary.min / 1000).toFixed(0)}k`} -{" "}
                            {job.salary.max < 1000 ? job.salary.max : `${(job.salary.max / 1000).toFixed(0)}k`}
                          </>
                        ) : (
                          <>
                            {job.salary.max && job.salary.max < 1000
                              ? job.salary.max
                              : job.salary.min && job.salary.min < 1000
                              ? job.salary.min
                              : `${(job.salary.max || job.salary.min) / 1000}k`}
                          </>
                        )}
                        {job.salary.type ? ` / ${job.salary.type === "month" ? "mth" : job.salary.type === "year" ? "yr" : job.salary.type}` : ""}
                      </>
                    ) : (
                      "--"
                    )}
                  </td>

                  <td className="py-4 px-2">{new Date(job.job_posted_date).toISOString().split("T")[0]}</td>
                  <td className="py-2 px-2">
                    <FormControl size="small" sx={{ minWidth: 120 }}>
                      <Select
                        value={executiveSelection[job.id] || ""}
                        onChange={(e) => handleExecutiveChange(job.id, e.target.value)}
                        displayEmpty
                        renderValue={(selected) => (selected ? selected : "Select")}
                      >
                        <MenuItem value="Vaishali Anand">Vaishali Anand</MenuItem>
                        <MenuItem value="Ishita Singh">Ishita Singh</MenuItem>
                      </Select>
                    </FormControl>
                  </td>

                  <td className="py-2 px-4">
                    <FormControl size="small" sx={{ minWidth: 130 }}>
                      <Select
                        multiple
                        value={channelSelection[job.id] || []}
                        onChange={(e) => handleChannelChange(job.id, e.target.value)}
                        displayEmpty
                        renderValue={(selected) =>
                          selected.length > 0 ? (
                            <div className="flex flex-wrap gap-1">
                              {selected.map((value) => (
                                <Chip key={value} label={value} size="small" />
                              ))}
                            </div>
                          ) : (
                            "Select"
                          )
                        }
                      >
                        <MenuItem value="Indeed">Indeed</MenuItem>
                        <MenuItem value="LinkedIn">LinkedIn</MenuItem>
                        <MenuItem value="Naukri">Naukri</MenuItem>
                        <MenuItem value="Monster">Monster</MenuItem>
                        <MenuItem value="Apna Jobs">Apna Jobs</MenuItem>
                      </Select>
                    </FormControl>
                  </td>
                  {selectedTab !== "paid" && (
                    <td className="py-2 px-4">
                      <FormControl size="small" sx={{ minWidth: 120 }}>
                        <Select
                          value={prioritySelection[job.id] || ""}
                          onChange={(e) => handlePriorityChange(job.id, e.target.value)}
                          displayEmpty
                          renderValue={(selected) => (selected ? selected : "Select")}
                        >
                          <MenuItem value="high">HIGH</MenuItem>
                          <MenuItem value="medium">MEDIUM</MenuItem>
                          <MenuItem value="low">LOW</MenuItem>
                        </Select>
                      </FormControl>
                    </td>
                  )}
                  <td className="py-2 px-3">
                    <Button 
                      variant="contained" 
                      color="primary" 
                      onClick={() => handleSave(job.id)}
                      className="px-4"
                      disabled={ !executiveSelection[job.id] || !channelSelection[job.id] || channelSelection[job.id].length === 0}
                    >
                      Post
                    </Button>
                  </td>
                </tr>
              )})
            ) : (
              <tr>
                <td colSpan={selectedTab === "paid" ? 9 : 10} className="py-4 px-6 text-center">
                  No jobs found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      
      <TablePagination
        component="div"
        count={totalCount}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={pageSize}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[2, 5, 10, 25, 50, 100]}
      />
    </div>
  );
};

export default JobMJobList;