import React, { useState, useEffect } from "react";
import { TablePagination } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { useQueryParams } from "../hooks";
import { useDebounce } from "../hooks";
import { useOutletContext, useLocation, useParams, useNavigate } from "react-router-dom";
import { getMarketplaceJobsData } from "../pages/Dashboard/helper";

export const MarketplaceJobs = () => {
  const [jobsData, setJobsData] = useState([]);
  const { getParam, setMultiParam } = useQueryParams();
  const currentPage = getParam("page");
  const currentLimit = getParam("pageSize");
  const [jobsPage, setJobsPage] = useState(parseInt(currentPage) || 0);
  const [jobsLimit, setJobsLimit] = useState(parseInt(currentLimit) || 10);
  const [jobsTotal, setJobsTotal] = useState(0);
  const [search, setSearch] = useState('');
  const debounceSearch = useDebounce(search, 1000);
  const context = useOutletContext();
  const location = useLocation();
  const navigate = useNavigate();
  const isVendorRoute = location.pathname.includes("Vendor");
  const { userid } = useParams();

  const handleNavigateBack = () => {
    const baseRoute = isVendorRoute
      ? `/UserManager/Vendor/vendor-info/${userid}`
      : `/UserManager/Client/client-info/${userid}`;
    navigate(`${baseRoute}?page=${getParam("page")}&pageSize=${getParam("pageSize")}&user-name=${getParam("user-name")}`);
  };

  const handleChangePage = (e, newPage) => {
    setJobsPage(newPage);
    fetchJobsData(newPage, jobsLimit);
  };

  const handleChangeRowsPerPage = (event) => {
    setJobsLimit(parseInt(event.target.value, 10));
    setJobsPage(0);
    fetchJobsData(0, parseInt(event.target.value, 10));
  };

  const fetchJobsData = async (page, limit, searchTerm) => {
    try {
      const response = await getMarketplaceJobsData(page + 1, limit, searchTerm, userid);
      setJobsData(response.data.jobs);
      setJobsTotal(response.data?.total);
    } catch (error) {
      console.error("Error fetching marketplace jobs:", error);
    }
  };

  useEffect(() => {
    const userType = isVendorRoute ? "Vendor" : "Client";
    context(["User Manager", userType, "Marketplace Jobs"]);
    fetchJobsData(jobsPage, jobsLimit, debounceSearch);
  }, [debounceSearch]);

  useEffect(() => {
    setMultiParam({ "page": jobsPage, "pageSize": jobsLimit });
  }, [jobsPage, jobsLimit]);

  return (
    <div className="flex flex-col">
      <div className="flex py-4 pl-6">
        <button 
          className="pl-2 pr-4 py-2 bg-[#2EBAA3] rounded-md text-[#fff]"
          onClick={handleNavigateBack}
        >
          <ArrowBack sx={{marginRight: "8px"}} /> Go Back
        </button>
      </div>
      <div className="p-5 shadow-md bg-white">
        <div className="grid grid-cols-6 gap-2">
          <h3
            className="col-span-5 text-lg uppercase tracking-wider font-bold"
            style={{ color: "#2EBAA3" }}
          >
            Marketplace Jobs
          </h3>
          <input
            type="text"
            id="search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Search"
            className="px-3 py-1.5 mr-2 mb-2 form-control block w-full text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none"
          />
        </div>
        <br />
        <div className="overflow-x-auto relative">
          <table className="w-full text-sm text-left text-gray-500">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50">
              <tr>
                <th scope="col" className="py-3 px-6 whitespace-nowrap">Job Name</th>
                <th scope="col" className="py-3 px-6 whitespace-nowrap">Salary</th>
                <th scope="col" className="py-3 px-6 whitespace-nowrap text-center">
                  <div>All</div>
                  <div>Applicants</div>
                </th>
                <th scope="col" className="py-3 px-6 whitespace-nowrap">
                  <div>Reviewed</div>
                </th>
                <th scope="col" className="py-3 px-6 whitespace-nowrap">
                  <div>Interview</div>
                  <div>Scheduled</div>
                </th>
                <th scope="col" className="py-3 px-6 whitespace-nowrap">Job Offered</th>
                <th scope="col" className="py-3 px-6 whitespace-nowrap">Hired</th>
                <th scope="col" className="py-3 px-6 whitespace-nowrap border-r-2 border-gray-300">Rejected</th>
                <th scope="col" className="py-3 px-6 whitespace-nowrap">Recommended</th>
                <th scope="col" className="py-3 px-6 whitespace-nowrap">Good Fit</th>
                <th scope="col" className="py-3 px-6 whitespace-nowrap">Average</th>
                <th scope="col" className="py-3 px-6 whitespace-nowrap">Below Average</th>
              </tr>
            </thead>
            <tbody>
              {jobsData?.length === 0 ? (
                <tr>
                  <td colSpan="11" className="py-4 px-6 text-center text-gray-500">No Data Found</td>
                </tr>
              ) : (
                jobsData.map((job) => (
                  <tr key={job.id} className="bg-white border-b">
                    <td className="py-4 px-6 whitespace-nowrap">{job.name}</td>
                    <td className="py-4 px-6">
                      {job.salary.currency === "INR" ? "₹" : "$"}
                      {job.salary.min}{job.salary.max ? ` - ${job.salary.max}` : ""}{" "}
                      /{job.salary.type}
                    </td>
                    <td className="py-4 px-6">{job.applicant_count}</td>
                    <td className="py-4 px-6">{job.applicationCounts?.screening || 0}</td>
                    <td className="py-4 px-6">{job.applicationCounts?.interview_scheduled || 0}</td>
                    <td className="py-4 px-6">{job.applicationCounts?.job_offered || 0}</td>
                    <td className="py-4 px-6">{job.applicationCounts?.hired || 0}</td>
                    <td className="py-4 px-6 border-r-2 border-gray-300">{job.applicationCounts?.reject || 0}</td>
                    <td className="py-4 px-6">{job?.applicationStrengthCounts?.Recommended || 0}</td>
                    <td className="py-4 px-6">{job?.applicationStrengthCounts?.["Good Fit"] || 0}</td>
                    <td className="py-4 px-6">{job?.applicationStrengthCounts?.Average || 0}</td>
                    <td className="py-4 px-6">{job?.applicationStrengthCounts?.["Below Average"] || 0}</td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
        <TablePagination
          component="div"
          count={jobsTotal}
          page={jobsPage}
          onPageChange={handleChangePage}
          rowsPerPage={jobsLimit}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[2, 5, 10, 25, 50, 100]}
        />
      </div>
    </div>
  );
};