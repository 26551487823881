import React, { useEffect, useState } from "react";
import Navbar from "../../../components/Navbar";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { useNavigate } from "react-router-dom";
import { Tabs, Tab, TablePagination } from "@mui/material";
import { getFreeList, getPaidList } from "./Helper";
import { useQueryParams } from "../../../hooks";
import { getSubscriedUser } from "../../SubscribedUser/helper";
import SearchableClientsDropdown from "../../../components/SearchableDropdown"; // Import the new component

const JobMSubList = () => {
  const [jobs, setJobs] = useState([]);
  const [tabValue, setTabValue] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();
  const [userList, setUserList] = useState([]);
  
  const { getParam, getAllQueryParamString, setMultiParam, removeParam } = useQueryParams();
  const currentPage = getParam("page");
  const currentLimit = getParam("pageSize");
  const [page, setPage] = useState(parseInt(currentPage) || 0);
  const [pageSize, setPageSize] = useState(parseInt(currentLimit) || 10);
  const [totalCount, setTotalCount] = useState(0);
  const [selectedPriority, setSelectedPriority] = useState('');
  const [selectedUser , setSelectedUser ] = useState('');
  const [sortOrder, setSortOrder] = useState('');

  const fetchUsers = async () => {
    try {
      const response = await getSubscriedUser(1, 100);
      if (response.data && response.data.result) {
        setUserList(response.data.result);
      }
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  }; 
  useEffect(() => {
    fetchJobs(searchTerm, 1, pageSize);
  }, [selectedUser, tabValue])

  const fetchJobs = async (search = searchTerm, currentPage = page + 1, rowsPerPage = pageSize) => {
    try {
      let response;
      if (tabValue === 0) {
        response = await getPaidList(currentPage, rowsPerPage, search, selectedUser, sortOrder);
      } else {
        response = await getFreeList(currentPage, rowsPerPage, search, selectedPriority, sortOrder);
      }
      setJobs(response.data.data);
      setTotalCount(response.data.total || 0);
    } catch (error) {
      console.error("Error fetching jobs:", error);
      setJobs([]);
      setTotalCount(0);
    }
  };  
  
  useEffect(() => {
    fetchUsers();
  }, []);

  useEffect(() => {
    if (tabValue === 1) {
      fetchJobs(searchTerm, 1, pageSize);
    }
  }, [selectedPriority]);

  useEffect(() => {
    fetchJobs(searchTerm, page + 1, pageSize);
  }, [page, pageSize, tabValue]);

  useEffect(() => {
    fetchJobs(searchTerm, 1, pageSize);
  }, [sortOrder]);
  

  useEffect(() => {
    setMultiParam({"page": page, "pageSize": pageSize});
  }, [page, pageSize]);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    setPage(0);
  };

  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    fetchJobs(value, page + 1, pageSize);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    fetchJobs(searchTerm, newPage + 1, pageSize);
  };

  const handleChangeRowsPerPage = (event) => {
    const newPageSize = parseInt(event.target.value, 10);
    setPageSize(newPageSize);
    setPage(0);
    fetchJobs(searchTerm, 1, newPageSize);
  };

  const handleClearFilters = () => {
    setSearchTerm("");
    setSelectedUser("");
    setSelectedPriority("");
    setSortOrder("");
    setPage(0);
    fetchJobs("", 1, pageSize);
  };

  const getItemIndex = (index) => page * pageSize + index + 1;

  return (
    <div className="p-5 shadow-md bg-white">
      <div className="flex justify-between">
        <div className="text-lg uppercase tracking-wider font-bold text-[#2EBAA3]">
          <h3>Manage Posted Jobs</h3>
        </div>
        <div className="flex">
        {tabValue === 1 && (
          <select
          value={selectedPriority}
          onChange={(e) => {
            setSelectedPriority(e.target.value);
          }}
          className="px-3 py-1.5 mr-2 mb-2 form-control block w-full text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out"
        >
          <option value="">Select Priority</option>
          <option value="high">HIGH</option>
          <option value="medium">MEDIUM</option>
          <option value="low">LOW</option>
        </select>   
        )}
        {tabValue === 0 && (
          <SearchableClientsDropdown 
            userList={userList} 
            selectedUser={selectedUser} 
            setSelectedUser={setSelectedUser} 
          />
        )}
        <input
          type="text"
          id="search"
          placeholder="Search"
          value={searchTerm}
          onChange={handleSearch}
          className="px-3 py-1.5 mr-2 mb-2 form-control block w-[150px] text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none"
        />
      <select
        value={sortOrder}
        onChange={(e) => {
          setSortOrder(e.target.value);
        }}
        className="px-3 py-1.5 mr-2 mb-2 form-control block w-[180px] text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out"
      >
        <option value="">Sort By Applicants</option>
        <option value="ASC">Ascending</option>
        <option value="DESC">Descending</option>
      </select>
      <button onClick={handleClearFilters} className="bg-[#2EBAA3] text-white px-4 h-[38px] rounded">
        Clear
      </button>
    </div>
    </div>
      <div className="flex justify-end">     
    </div>

      <br />
      <Tabs value={tabValue} onChange={handleTabChange} className="font-bold">
        <Tab label="Paid Jobs" />
        <Tab label="Free Jobs" />
      </Tabs>
      <div className="overflow-x-auto relative">
        <table className="w-full text-sm text-left text-gray-500">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50">
            <tr>
              <th scope="col" className="py-3 px-3 uppercase w-[50px]">
                ID
              </th>
              <th scope="col" className="py-3 px-6 uppercase w-[200px]">
                Job Name
              </th>
              <th scope="col" className="py-3 px-6 uppercase w-[200px]">
                Company Name
              </th>
              <th scope="col" className="py-3 px-10 uppercase w-[200px]">
                User Type
              </th>
              <th scope="col" className="py-3 px-10 uppercase w-[200px]">
                Salary
              </th>
              <th scope="col" className="py-3 px-6 uppercase w-[250px]">
                Broadcasted By
              </th>
              <th scope="col" className="py-3 px-6 uppercase w-[250px]">
                {tabValue === 0 ? "Broadcasted On" : "Published On"}
              </th>
              {tabValue === 1 && (
              <th scope="col" className="py-3 px-6 uppercase w-[250px]">
                Priority
              </th>
              )}
              <th scope="col" className="py-3 px-6 whitespace-nowrap text-center w-[150px]">
                <div>All</div>
                <div>Applicants</div>
              </th>
              <th scope="col" className="py-3 px-6 whitespace-nowrap w-[150px]">
                <div>Reviewed</div>
              </th>
              <th scope="col" className="py-3 px-6 whitespace-nowrap w-[150px]">
                <div>Interview</div>
                <div>Scheduled</div>
              </th>
              <th scope="col" className="py-3 px-6 whitespace-nowrap w-[150px]">Job Offered</th>
              <th scope="col" className="py-3 px-6 whitespace-nowrap w-[150px]">Hired</th>
              <th scope="col" className="py-3 px-6 whitespace-nowrap border-r-2 border-gray-300 w-[150px]">Rejected</th>
              <th scope="col" className="py-3 px-6 whitespace-nowrap w-[150px]">Recommended</th>
              <th scope="col" className="py-3 px-6 whitespace-nowrap w-[150px]">Good Fit</th>
              <th scope="col" className="py-3 px-6 whitespace-nowrap w-[150px]">Average</th>
              <th scope="col" className="py-3 px-6 whitespace-nowrap w-[150px]">Below Average</th>
            </tr>
          </thead>
          <tbody>
            {jobs && jobs.length > 0 ? (
              jobs.map((job, index) => (
                <tr className="bg-white border-b" key={index}>
                  <th scope="row" className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap cursor-pointer">
                    {getItemIndex(index)}
                  </th>
                  <td className="py-4 px-6">{job.name}</td>
                  <td className="py-4 px-6">{job.vendorName}</td>
                  <td className="py-4 px-6">{job.role}</td>
                  <td className="py-4 px-2">
                  {job.salary && (job.salary.min || job.salary.max) ? (
                    <>
                      {job.salary.currency === "INR" ? "₹" : job.salary.currency === "USD" ? "$" : ""}
                      {job.salary.min && job.salary.max ? (
                        <>
                          {job.salary.min < 1000 ? job.salary.min : `${(job.salary.min / 1000).toFixed(0)}k`} -{" "}
                          {job.salary.max < 1000 ? job.salary.max : `${(job.salary.max / 1000).toFixed(0)}k`}
                        </>
                      ) : (
                        <>
                          {job.salary.max && job.salary.max < 1000
                            ? job.salary.max
                            : job.salary.min && job.salary.min < 1000
                            ? job.salary.min
                            : `${(job.salary.max || job.salary.min) / 1000}k`}
                        </>
                      )}
                      {job.salary.type ? ` / ${job.salary.type === "month" ? "mth" : job.salary.type === "year" ? "yr" : job.salary.type}` : ""}
                    </>
                  ) : (
                    "--"
                  )}
                </td>
                  <td className="py-4 px-6">{job.broadcast_by}</td>
                  <td className="py-4 px-6">{job.broadcast_platforms ? job.broadcast_platforms.join(", ") : ""}</td>
                  {tabValue === 1 && <td className="py-4 px-6">{job?.priority}</td>}
                  <td className="py-4 px-12">{job.applicant_count || 0}</td>
                  <td className="py-4 px-12">{job.applicationCounts?.screening || 0}</td>
                  <td className="py-4 px-12">{job.applicationCounts?.interview_scheduled || 0}</td>
                  <td className="py-4 px-12">{job.applicationCounts?.job_offered || 0}</td>
                  <td className="py-4 px-8">{job.applicationCounts?.hired || 0}</td>
                  <td className="py-4 px-12 border-r-2 border-gray-300">{job.applicationCounts?.reject || 0}</td>
                  <td className="py-4 px-12">{job?.applicationStrengthCounts?.Recommended || 0}</td>
                  <td className="py-4 px-12">{job?.applicationStrengthCounts?.["Good Fit"] || 0}</td>
                  <td className="py-4 px-12">{job?.applicationStrengthCounts?.Average || 0}</td>
                  <td className="py-4 px-12">{job?.applicationStrengthCounts?.["Below Average"] || 0}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={tabValue === 0 ? 16 : 17} className="py-10 px-6 text-center text-lg font-semibold text-gray-500">
                  No data found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      
      <TablePagination
        component="div"
        count={totalCount}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={pageSize}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[2, 5, 10, 25, 50, 100]}
      />
    </div>
  );
};

export default JobMSubList;